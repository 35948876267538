import img1 from "../images/avatar/1.png";
import img2 from "../images/avatar/2.png";
import img3 from "../images/avatar/3.png";
import img4 from "../images/avatar/4.png";
import img5 from "../images/avatar/5.png";
import img6 from "../images/avatar/6.png";
import img7 from "../images/avatar/7.png";
import img8 from "../images/avatar/8.png";
import img9 from "../images/avatar/9.png";
import img10 from "../images/avatar/10.png";
import img11 from "../images/avatar/11.png";
import img12 from "../images/avatar/12.png";
import img13 from "../images/avatar/13.png";
import img14 from "../images/avatar/14.png";
import img15 from "../images/avatar/15.png";
import img16 from "../images/avatar/16.png";

const dataTeam = [
    {
        id: 1,
        img: img2,
        name: "Burak Sormagec",
        unit: "Founder",
        social: {
            discord: "",
            linkedin: "https://www.linkedin.com/in/bsormagec",
            twitter: "https://twitter.com/bsormagec",
            facebook: "",
            instagram: "",
            web: ""
        }
    },
    {
        id: 2,
        img: img1,
        name: "Cur8or",
        unit: "Co Founder / Game Design",
        social: {
            discord: "",
            linkedin: "https://www.linkedin.com/in/samcliffordcurate",
            twitter: "https://twitter.com/cur8orofearth",
            facebook: "",
            instagram: "",
            web: ""
        }
    },
    {
        id: 3,
        img: img12,
        name: "Keros",
        unit: "Game Dev Lead",
        social: {
            discord: "",
            linkedin: "",
            twitter: "https://twitter.com/KaetramOfficial",
            facebook: "",
            instagram: "",
            web: ""
        }
    },
    {
        id: 6,
        img: img11,
        name: "Ferdi",
        unit: "Sr Backend Developer",
        social: {
            discord: "",
            linkedin: "https://www.linkedin.com/in/funal",
            twitter: "https://twitter.com/_ferdiunal",
            facebook: "",
            instagram: "",
            web: ""
        }
    },
    {
        id: 9,
        img: img13,
        name: "Ebu",
        unit: "Sr Frontend Developer",
        social: {
            discord: "",
            linkedin: "https://tr.linkedin.com/in/ebubekir-eryigit-696838121",
            twitter: "https://twitter.com/ebuery01",
            facebook: "",
            instagram: "",
            web: ""
        }
    },
    {
        id: 7,
        img: img10,
        name: "Kuter",
        unit: "Backend Developer",
        social: {
            discord: "",
            linkedin: "https://www.linkedin.com/in/kuter-dinmezpinar",
            twitter: "https://twitter.com/UngratefulRaven",
            facebook: "",
            instagram: "",
            web: ""
        }
    },
    {
        id: 4,
        img: img4,
        name: "Mike",
        unit: "Sr Game Developer",
        social: {
            discord: "",
            linkedin: "",
            twitter: "",
            facebook: "",
            instagram: "",
            web: ""
        }
    },
    {
        id: 11,
        img: img3,
        name: "Sinan",
        unit: "Senior Designer",
        social: {
            discord: "",
            linkedin: "https://www.linkedin.com/in/sasil",
            twitter: "https://twitter.com/sekiz",
            facebook: "",
            instagram: "",
            web: ""
        }
    },
    {
        id: 12,
        img: img4,
        name: "Sakini",
        unit: "Senior Designer",
        social: {
            discord: "",
            linkedin: "",
            twitter: "",
            facebook: "",
            instagram: "",
            web: ""
        }
    },
    {
        id: 3,
        img: img6,
        name: "Selin",
        unit: "Social Magician",
        social: {
            discord: "",
            linkedin: "https://www.linkedin.com/in/selingdemir",
            twitter: "https://twitter.com/selingdemir",
            facebook: "",
            instagram: "",
            web: ""
        }
    },
    // {
    //     id: 8,
    //     img: img8,
    //     name: "Ismet",
    //     unit: "Sr Frontend Developer",
    //     social: {
    //         discord: "",
    //         linkedin: "",
    //         twitter: "",
    //         facebook: "",
    //         instagram: "",
    //         web: ""
    //     }
    // },
    // {
    //     id: 10,
    //     img: img9,
    //     name: "Faruk",
    //     unit: "Senior Devops Engineer",
    //     social: {
    //         discord: "",
    //         linkedin: "",
    //         twitter: "",
    //         facebook: "",
    //         instagram: "",
    //         web: ""
    //     }
    // },
    // {
    //     id: 13,
    //     img: img1,
    //     name: "Tolga",
    //     unit: "Senior Magician",
    //     social: {
    //         discord: "",
    //         linkedin: "",
    //         twitter: "",
    //         facebook: "",
    //         instagram: "",
    //         web: ""
    //     }
    // },
    // {
    //     id: 14,
    //     img: img5,
    //     name: "Idil",
    //     unit: "Senior Magician",
    //     social: {
    //         discord: "",
    //         linkedin: "",
    //         twitter: "",
    //         facebook: "",
    //         instagram: "",
    //         web: ""
    //     }
    // },
    // {
    //     id: 15,
    //     img: img15,
    //     name: "Sude",
    //     unit: "Social Magician",
    //     social: {
    //         discord: "",
    //         linkedin: "",
    //         twitter: "",
    //         facebook: "",
    //         instagram: "",
    //         web: ""
    //     }
    // },
    // {
    //     id: 16,
    //     img: img16,
    //     name: "Ufuk",
    //     unit: "AI Magician",
    //     social: {
    //         discord: "",
    //         linkedin: "",
    //         twitter: "",
    //         facebook: "",
    //         instagram: "",
    //         web: ""
    //     }
    // },
];

export default dataTeam;
