const dataTypes = [
    {
        title: "Project",
        pluralTitle: "Projects",
        slug: "project"
    },
    {
        title: "Game",
        pluralTitle: "Games",
        slug: "game"
    },
    {
        title: "NFT",
        pluralTitle: "NFT'S",
        slug: "nft"
    },
    {
        title: "Tech",
        pluralTitle: "Techs",
        slug: "tech"
    }
]

export default dataTypes