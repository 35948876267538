const dataReleases = [
    {
        id: 1,
        time: "End of 2023",
        text1: "Etharis: Finalizing Map Design",
        text2: "Completion of mob sprites and balancing",
        text3: "Godot engine conversion completion",
        status: "active",
    },
    {
        id: 2,
        time: "Q1 2024",
        text1: "Etharis: Start of Alpha Testing",
        text2: "Broken Toys: Development Progress",
        text3: "ERC721ODM Beta Testing for 200k Players",
        status: "active",
    },
    {
        id: 3,
        time: "Q2 2024",
        text1: "Etharis: Beta Testing Commencement",
        text2: "Broken Toys: Alpha Testing Starts",
        text3: "Website Launch for Etharis and Broken Toys",
        status: "active",
    },
    {
        id: 4,
        time: "Q3 2024",
        text1: "Broken Toys: Beta Testing Begins",
        text2: "Continuous Development and Testing of ERC721ODM",
        text3: "Further Enhancements to Etharis Post-Beta",
        status: "",
    },
    {
        id: 5,
        time: "December 2023",
        text1: "Quantum Whiskers: First 10,000 Samples Reveal",
        text2: "AI Model Training and Generation",
        text3: "Preview of Quantum Whiskers NFTs",
        status: "",
    },
    {
        id: 6,
        time: "Throughout 2024",
        text1: "Ongoing Development of W3N Games",
        text2: "Scaling and Bandwidth Testing for ERC721ODM",
        text3: "Enhancing User Experience and Performance",
        status: "",
    },
    // More to be added
];

export default dataReleases;
