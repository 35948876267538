import img1 from "../images/common/project_1.png";
import img2 from "../images/common/project_2.png";
import img3 from "../images/common/project_3.png";
import img4 from "../images/common/project_4.png";

import quantumImg from "../images/slider/quantumWhiskers.png";
import exampleImg from "../images/common/details_1.jpg"




// etharis images
import etharis_places from '../images/projects/etharis/places.png'
import etharis_adventure from '../images/projects/etharis/adventure.png'
import etharis_dungeons from '../images/projects/etharis/dungeons.png'
import etharis_airdrop from '../images/projects/etharis/airdrop.png'
import etharis_races from '../images/projects/etharis/races.png'
import etharis_banner_img from '../images/projects/etharis/banner.png'


// broken toys images
import broken_optic from "../images/projects/broken-toys/Optic_Omniwrench_Schematic_Dull.webp"
import broken_robotic from "../images/projects/broken-toys/Robotic_Snail_Schematic_Dull.webp"
import broken_list_img from "../images/projects/broken-toys/broken-toys-logo.png"
import broken_banner_img from "../images/projects/broken-toys/coming-soon.png"
import broken_logo from "../images/projects/broken-toys/broken-toys-logo_old.png"

// erc7210dm images
import erc7210dm_img_1 from "../images/projects/erc7210dm/image1.jpeg"
import erc7210dm_img_2 from "../images/projects/erc7210dm/image2.jpeg"
import erc7210dm_img_3 from "../images/projects/erc7210dm/image3.png"

// whislers images
import whislers_img_1 from "../images/projects/whislers/1.jpg"
import whislers_img_2 from "../images/projects/whislers/2.jpg"


// types = project, game, nft, tech

const dataProject = [
    {
        id: 1,
        slug: "erc7210dm",
        type: "project",
        img: img1, // Assuming img1 is relevant to ERC721ODM
        listImg: erc7210dm_img_1,
        title: "ERC721ODM",
        desc: "A groundbreaking protocol for dynamic NFT metadata management on-chain, revolutionizing the way NFTs interact within games.",
        price_1: "Innovation",
        price_2: "Blockchain",
        price_3: "NFTs",
        details: {
            sections: [
                {
                    title: "The Future of Dynamic NFTs for Gaming Is Here! ",
                    text: [
                        "Introducing ERC721ODM – our cutting-edge protocol engineered to revolutionize in-game assets and player experiences. As we enter a new era where gaming isn't just play but an immersive experience, the backbone of this transformation lies in the metadata. ERC721ODM is designed to breathe life into NFTs, making them dynamic, responsive, and more engaging than ever before.",
                        "Imagine in-game assets that evolve with the player's journey, weapons that age, characters that grow, and items that carry the legacy of the battles they've seen. That's the power of dynamic metadata management with ERC721ODM. But we're not stopping there. With the advent of EIP-4337 & EIP-6551, our protocol enables gasless transactions and seamless onboarding of players, creating a frictionless entry into the gaming worlds you make.",
                        "ERC721ODM is not just a tool; it's a gaming revolution in a box. It offers the following game-changing advantages:",
                        "Dynamic Metadata Updates: Games are living entities, and their assets should be too. With ERC721ODM, update NFTs on the fly to reflect in-game events and achievements without the gas costs traditionally associated with such actions.",
                        "Gasless Player Onboarding: Utilizing the latest in Ethereum Improvement Proposals, EIP-4337 & EIP-6551, ERC721ODM enables new players to jump into your game without the upfront blockchain costs, smoothing out their first contact with your universe.",
                        "Seamless Integration: Designed with flexibility in mind, ERC721ODM can be integrated into existing game infrastructures, allowing for a plug-and-play experience for developers. This means less time coding and more time creating.",
                        "Beneficial for All: Whether you're a game developer, a gaming company, or running an NFT project, ERC721ODM offers a toolkit that unlocks new possibilities. Enhance player retention, introduce novel gameplay elements, and create a rich, collectible ecosystem.",
                        "We believe in empowering creators, which is why ERC721ODM was designed as a toolkit for innovation. Our technology isn't just another blockchain gimmick; it's a new chapter in gaming history. Games and NFT projects can now harness the power of blockchain without the barriers that once stood in the way of creativity.",
                        "As the gaming industry evolves, ERC721ODM stands ready to support creators in building the immersive experiences of tomorrow. Get ready to redefine what it means to own, play, and engage with digital assets.",
                        "Stay tuned for more updates as we continue to enhance ERC721ODM, and join us in shaping the future of gaming. The next level awaits!",
                    ],
                    space: false,
                    // links: [
                    //     {
                    //         type: "twitter",
                    //         url: "#"
                    //     },
                    //     {
                    //         type: "web",
                    //         url: "#"
                    //     }
                    // ],
                },
                {
                    row: [
                        {
                            col: 6,
                            img: erc7210dm_img_1
                        },
                        {
                            col: 6,
                            img: erc7210dm_img_2
                        }
                    ]
                },
                {
                    title: "The Ultimate Fix for On-Chain NFT Metadata Chaos!",
                    text: [
                        "1 - NFTs are the modern-day Mona Lisas, but their metadata is like a disorganized art gallery. Enter ERC721ODM—your on-chain curator!",
                        "2 - What's ERC721ODM? <br/> Consider it the Dewey Decimal System for NFT metadata, but make it blockchain!",
                        "3 - Why Choose It? <br/> Say goodbye to your metadata woes. ERC721ODM organizes, updates, and secures all your NFT data on-chain.",
                        "4 - Features: <br/> One-Stop Metadata, Permission Control, Time-Specific Data, Token Pause/Resume, Swift Data Access",
                        "5 - Mechanics Built atop OpenZeppelin's frameworks, this gem lets you: <br/> Juggle contracts like a pro, Set your metadata, Period assignments, Suspend rogue tokens",
                        "6 - For Code Wizards: <br/> View functions make it a breeze to access contract data. It’s like having a blockchain GPS!",
                        "7 - Get Started <br /> All you need is to reach out to <a href='https://twitter.com/PagziTech' target='_blank'>@PagziTech</a> for collaboration.",
                        "8 - Join the Dialogue <br/> Your feedback shapes ERC721ODM. We want to hear from you!",
                        "9 - Future Plans <br/> More features, more blockchain goodness. ERC721ODM is your ticket to a streamlined NFT future.",
                        "10 - Stay Updated <br/> We’re on all your favourite platforms. Follow us and never miss an update. <a href='https://twitter.com/PagziTech' target='_blank'>@PagziTech</a> <a href='https://twitter.com/Web3Nc' target='_blank'>@Web3Nc</a>",
                        "11 - In Conclusion <br/> We're buzzing about ERC721ODM and want you on this ride. Let’s make on-chain NFT metadata a piece of cake!"
                    ],
                    img: erc7210dm_img_3
                },
                {
                    title: "Introducing ERC721ODM - Revolutionizing NFT Metadata Management On-Chain!",
                    text: [
                        "1 - NFTs are hot, but managing their metadata? Not so much. Say hello to ERC721ODM, the future of on-chain NFT metadata management!",
                        "2 - What is ERC721ODM? <br/> A smart contract-based protocol that serves as the one-stop-shop for all your NFT metadata needs right on the blockchain!",
                        "3 - Why ERC721ODM? <br/> Need help with juggling multiple contracts and inconsistent metadata? ERC721ODM is your on-chain librarian for NFT metadata.",
                        "4 - Key Features: <br/> Centralized Metadata, Access Control, Period Management, Suspend/Unsuspend Tokens, Retrieve Data Easily",
                        "5 - How It Works? <br/> Built on robust OpenZeppelin contracts, ERC721ODM allows you to: <br/> Manage token contracts, Set metadata, Control periods, Suspend tokens if needed",
                        "6 - For Devs <br/> No more guesswork! View functions let you fetch all relevant contract details in an instant.",
                        "7 - Dive In <br/> The source code and docs are all on our GitHub. Fork it, clone it, love it! ",
                        "8 - Community Calls <br/> Got questions? Feedback? We're all ears! Let's make ERC721ODM the gold standard together.",
                        "9 - What's Next? <br/> We're rolling out more features and integrations. The future is on-chain, and ERC721ODM is your passport.",
                        "10 - Connect <br/> Follow us for the latest! Twitter, Discord, and our blog are your go-to sources. <a href='https://twitter.com/PagziTech' target='_blank'>@PagziTech</a> <a href='https://twitter.com/Web3Nc' target='_blank'>@Web3Nc</a>",
                        "11 - Wrapping Up <br/> We're stoked about ERC721ODM and can’t wait for you to join us. Together, we're redefining digital asset management! "
                    ]
                }
            ],
            // team: [1, 3, 5],
            showRoadmap: true
        }
    },
    {
        id: 2,
        img: img2, // Assuming img2 is relevant to Quantum Whiskers | Odyssey
        listImg: whislers_img_1,
        slug: "quantum-whislers-odyssey",
        type: "nft",
        title: "Quantum Whiskers | Odyssey",
        desc: "An epic adventure game set in a multiverse, featuring sentient felines with cosmic powers exploring diverse realms.",
        price_1: "Adventure",
        price_2: "Strategy",
        price_3: "Multiverse",
        details: {
            sections: [
                {
                    title: "A Galactic Art Odyssey",
                    text: [
                        "Embark on a Cosmic Journey with Quantum Whiskers <br/> Welcome to Quantum Whiskers, an extraordinary NFT project where art meets the boundless mysteries of space. Dive into a universe where the last legacy of humanity thrives through the eyes of evolved cats - Bengal Vortexes, Siamese Shadows, and Maine Coon Meteors. Each NFT in this collection is more than a digital asset; it's a window into a grand narrative that spans galaxies."
                    ]
                },
                {
                    title: "Artistic Brilliance in the Digital Realm",
                    text: [
                        "Quantum Whiskers stands at the confluence of imaginative storytelling and stunning digital art. Our collections are masterpieces, each depicting the unique journey and evolution of our cosmic felines. As a Quantum Whiskers holder, you embrace a piece of this interstellar saga, a fragment of a story that continues to unfold with each new artwork.",
                    ]
                },
                {
                    title: "A Community of Dreamers and Storytellers",
                    text: [
                        "At its heart, Quantum Whiskers is about community. It's a platform for art lovers, storytellers, and dreamers who are captivated by the mysteries of the universe. Engage with a global community of enthusiasts, share your insights, and contribute to the ever-expanding Quantum Whiskers narrative."
                    ]
                },
                {
                    title: "Why Quantum Whiskers?",
                    text: [
                        "Artistic Excellence: Each NFT is a unique piece of art, blending cosmic wonder with digital creativity. <br/> Engaging Narrative: <br/> The story of Quantum Whiskers is rich, dynamic, and constantly evolving, inviting you to be part of its unfolding. Community Focus: <br/> Join a thriving community where your voice matters, and your passion for art and storytelling finds a home. <br/> Join the Odyssey <br/> Quantum Whiskers is not just a collection; it's an invitation to journey through the cosmos, where art transcends the digital and becomes a part of something larger - a legacy, a narrative, a community. Embrace the adventure, become a part of Quantum Whiskers, and let your imagination soar across the universe."
                    ]
                },
                {
                    row: [
                        {
                            col: 6,
                            img: whislers_img_1
                        },
                        {
                            col: 6,
                            img: whislers_img_2
                        }
                    ]
                }
            ],
            showRoadmap: false
        },
    },
    {
        id: 3,
        img: img3, // Assuming img3 is relevant to Etharis
        listImg: etharis_adventure,
        slug: "etharis",
        type: "game",
        title: "Etharis",
        desc: "A rich, faction-based strategy game set on a continent teetering on the brink of war, where players' choices shape the future.",
        price_1: "Strategy",
        price_2: "Roleplay",
        price_3: "Conflict",
        details: {
            sections: [
                {
                    title: "Etharis",
                    text: "The continent of Etharis is vast and diverse, home to three major factions: the Steampunks, Cyberpunks, and Sandpunks. The continent is surrounded by various islands, each with its own unique challenges and inhabitants. The landscape ranges from sand dunes and grasslands to mountains and forests.",
                    img: etharis_places
                },
                {
                    text: ['The continent of Etharis is on the brink of all-out war, as each faction struggles for dominance. Rumors of an ancient artifact called "The Heart of Etharis" have surfaced. This mysterious artifact is said to grant its possessor control over the land and its elements. The factions believe that by securing this artifact, they can ensure their supremacy and bring peace to the continent on their terms.', `Players must navigate the treacherous political landscape, exploring the vast continent and its surrounding islands to uncover clues about the artifact's location. Along the way, they will encounter ancient dragons guarding long-forgotten treasures and face a myriad of powerful creatures.`],
                },
                {
                    row: [
                        {
                            col: '7',
                            title: "Dive into the adventure zone with our interactive map",
                            text: ['Discover more by simply clicking on specific locations to unravel the captivating lore, thrilling quests, and specialities of each unique area.',
                                `Visit the bustling cities of Frostfall, Brassport, Steamhorn, and Ember, and discover the marvels of steam-powered engineering in the capital city of Clockwork.`,
                                `Navigate the advanced city of Hexis and uncover the cutting-edge technology that defines the Cyberpunk society.`,
                                `Immerse yourself in the mystical cities of Pondcrest, Mirage, and Solar, and witness the awe-inspiring magic of the Sandpunks in the capital city of Zephyr.`]
                        },
                        {
                            col: '5',
                            img: etharis_adventure
                        }
                    ]
                },
                {
                    title: "Game Features at a Glance",
                    row_alignCenter: true,
                    row: [
                        {
                            col: '7',
                            text: [`Venture into dungeons, explore new places, uncover hidden gems on the map – embark on an epic journey of discovery!`,
                                `Equip your weapon, upgrade, assemble your team and prepare for battle. There's plenty to explore in Etharis! `]
                        },
                        {
                            col: '5',
                            img: etharis_dungeons
                        },
                        {
                            col: '4',
                            img: etharis_airdrop
                        },
                        {
                            col: '8',
                            text: [`Venture into dungeons, explore new places, uncover hidden gems on the map – embark on an epic journey of discovery!`,
                                `Equip your weapon, upgrade, assemble your team and prepare for battle. There's plenty to explore in Etharis! `]
                        },
                    ]
                },
                {
                    img: etharis_races
                },
                {
                    text: `Experience the best of both worlds – play the game, and collect rewards simultaneously! Dive into the excitement, have fun, and build your collection in this gaming adventure that offers the perfect blend of entertainment and rewards.`
                },
                {
                    customField: 'etharis_buttons'
                }
            ],
            customField: 'etharis_buttons',
            showRoadmap: true,
            bannerImg: etharis_banner_img
        },
    },
    {
        id: 4,
        img: img4, // Assuming img4 is relevant to Broken Toys
        listImg: broken_list_img,
        slug: "broken-toys",
        type: "game",
        title: "Broken Toys",
        desc: "A roguelike RPG set aboard a generation ship, where players navigate as mutants and shape the destiny of travelers.",
        price_1: "RPG",
        price_2: "Survival",
        price_3: "Exploration",
        details: {
            sections: [
                {
                    title: "About Project",
                    space: true,
                    text: "Broken Toys is digital collection and roguelike RPG set aboard a mysterious generation ship. Through play and dynamic metadata, participants can build and own their player characters."
                },
                {
                    img: broken_logo
                },
                {
                    img: broken_optic,
                    text: ['<i>Parents are now building mentor toys to prepare the next generation of engineers, pilots, and captains our children sleeping safe in their birth creche. Doubtless, fear is that the radiation sickness will take this generation before we even meet our progeny to train them.</i> - <b>excerpt from Cerulean log entry 238b</b>']
                },
                {
                    img: broken_robotic,
                    text: [`<i>The monitor readouts from the birth creche showed anomalies in the genetic matrix so the remaining scientists woke three of their eldest from cryosleep.  Each displays a beneficial adaptive genetic mutation!</i> - <b>excerpt from Taupe log entry 11j</b>`]
                }
            ],
            bannerImg: broken_banner_img
        },
    },
    // {
    //     id: 6,
    //     img: img4, // Assuming img4 is relevant to Broken Toys
    //     listImg: exampleImg,
    //     slug: "test-tech",
    //     type: "tech",
    //     title: "test tech",
    //     desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur ea eius iure mollitia numquam quae quaerat voluptatibus!",
    //     price_1: "RPG",
    //     price_2: "Survival",
    //     price_3: "Exploration",
    //     details: {
    //         showRoadmap: true
    //     },
    // },
    // ... (Continue for other projects, possibly other aspects of the games or related projects)
];

export default dataProject;
