import React  from 'react';
import '../styles.scss'
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { Link } from 'react-router-dom';

Banner3.propTypes = {
    data : PropTypes.array,
};

function Banner3 (props) {
    const {data} = props;
    return (

        <div className="page-title st3">
            <div className="overlay">
            </div>
            <div className="slider-main">
            <Swiper
                modules={[Navigation]}
                    spaceBetween={0}
                    slidesPerView={1}
                    navigation 
                >
                {
                    data.slice(0,4).map(item => (
                        <SwiperSlide key={item.id}>
                            <div className="slider-st3">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="box-slider">
                                                <div className="content-box">
                                                    <h1 className="title" >{item.heading}</h1>
                                                    <p className="sub-title">{item.sub_heading}</p>
                                                    <div className="wrap-btn">
                                                        <Link to={item.path} className="tf-button style2">
                                                            {item.btnText}
                                                        </Link>
                                                    </div>
                                                    <div className="flat-box" > 
                                                        <div className="box-couter">
                                                            <p>{item.extra1a}</p>
                                                            <div className="number-content">
                                                                <h6 className="count-number2">{item.extra1b}</h6>
                                                            </div>
                                                        </div>
                                                        <div className="box-couter">
                                                            <p>{item.extra2a}</p>
                                                            <div className="number-content">
                                                                <h6 className="count-number2">{item.extra2b}</h6>
                                                            </div>
                                                        </div>
                                                        <div className="box-couter">
                                                            <p>{item.extra3a}</p>
                                                            <div className="number-content">
                                                                <h6 className="count-number2">{item.extra3b}</h6> 
                                                            </div>
                                                        </div> 
                                                    </div>
                                                </div>
                                                <div className="image">
                                                    <img className="img_main" 
                                                    src={item.img} 
                                                    alt={item.heading}
                                                    style={item.imgStyle}
                                                     />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
            </div>
        </div>
    );
}

export default Banner3;