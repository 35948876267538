import React from 'react';

import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


function CTA(props) {


    const location = useLocation()

    return (
        <section className="tf-section tf_CTA">

            <div className="container relative">
                <div className="overlay">
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="tf-title left mt66" data-aos="fade-up" data-aos-duration="800">
                            <h2 className="title">
                                Launch with ERC721ODM OR W3NGAME
                            </h2>
                            <p className="sub">Full support in project integration</p>
                            {
                                location.pathname !== "/contact" ? <div className="wrap-btn">
                                    <Link to="/contact" className="tf-button style3">
                                        Contact Now
                                    </Link>
                                </div> : ""
                            }
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="image_cta" data-aos="fade-left" data-aos-duration="1200">
                            <img className="move4" src={require("../../../assets/images/logo/w3n_discord.png")} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CTA;