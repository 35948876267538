// import img1 from "../images/slider/w3n-slider.png";
import img1 from "../images/slider/nfts-in-gaming.png";
import img2 from "../images/slider/cat-slider-image.png";
import img3 from "../images/slider/etharis.png";
import img4 from "../images/slider/brokenToys.png";

const dataBanner = [
    {
        id: 1,
        heading: "Revolutionizing NFTs in Gaming with ERC721ODM",
        sub_heading:
            "Experience dynamic, on-chain NFT metadata management for immersive gaming.",
        img: img1,
        btnText: "Read More",
        path: "/project/erc7210dm",
        extra2a: "Easy Implementation",
        extra2b: "5 min setup",
        extra3a: "Tested Up To",
        extra3b: "200k Calls",
        imgStyle: {
            maxWidth: "80%"
        }
    },
    // {
    //     id: 2,
    //     heading: "Embark on Quantum Whiskers | Odyssey",
    //     sub_heading:
    //         "Dive into an epic saga with sentient felines in the multiverse of Quantum Whiskers | Odyssey.",
    //     img: img2,
    //     btnText: "Coming Soon",
    //     path: "#",
    // },
    {
        id: 3,
        heading: "Discover the World of Etharis",
        sub_heading:
            "Explore the factional conflicts and rich storylines in the dynamic universe of Etharis.",
        img: img3,
        btnText: "Discover",
        path: "/game/etharis",
    },
    {
        id: 4,
        heading: "Survive the Challenges in Broken Toys",
        sub_heading:
            "Navigate a generation ship's mystery and survival in the action roguelike RPG, Broken Toys.",
        img: img4,
        btnText: "Coming Soon",
        path: "#",
    },
];

export default dataBanner;
