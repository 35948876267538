const dataFaq = [
    {
        id: 1,
        title: "What is ERC721ODM and how does it impact gaming?",
        content:
            "ERC721ODM is a smart contract-based protocol designed for on-chain NFT metadata management. It revolutionizes gaming by allowing dynamic updates to NFTs, reflecting in-game events and achievements without traditional gas costs. This enhances the gaming experience by making in-game assets more interactive and responsive.",
    },
    {
        id: 2,
        title: "What are the benefits of using ERC721ODM for NFT projects?",
        content:
            "ERC721ODM offers several advantages for NFT projects, including centralized metadata management, access control, and the ability to manage token contracts efficiently. It simplifies handling multiple contracts and inconsistent metadata, making it an essential tool for dynamic NFTs in gaming and other applications.",
    },
    {
        id: 3,
        title: "What are the benefits of dynamic metadata in gaming?",
        content:
            "Dynamic metadata allows in-game assets to evolve in real-time, reflecting players' achievements and actions. This brings a new level of interaction and personalization to gaming, making the experience more engaging and tailored to individual gameplay styles.",
    },
    {
        id: 4,
        title: "How does gasless player onboarding work in ERC721ODM?",
        content:
            "ERC721ODM utilizes Ethereum Improvement Proposals like EIP-4337 & EIP-6551 to enable gasless transactions. This allows new players to join games without incurring upfront blockchain costs, smoothing their entry into the gaming world and enhancing overall accessibility.",
    },
    {
        id: 5,
        title: "Can ERC721ODM be integrated into existing game infrastructures?",
        content:
            "Yes, ERC721ODM is designed for flexibility and can be seamlessly integrated into existing game infrastructures. This allows developers to easily adopt dynamic NFT features in their games without extensive overhauls.",
    },
    {
        id: 6,
        title: "How does ERC721ODM handle metadata updates?",
        content:
            "ERC721ODM allows real-time metadata updates for NFTs on the blockchain. This means in-game assets can dynamically change based on events or achievements, ensuring a more interactive and engaging experience for players.",
    },
    {
        id: 7,
        title: "What sets Etharis apart from other strategy games?",
        content:
            "Etharis is a unique strategy game set on a continent with three distinct factions: Cyberpunks, Steampunks, and Sandpunks. It features a rich storyline, deep world-building, and the opportunity for players to align with a faction and influence the game's outcome. Etharis stands out with its focus on factional politics, moral dilemmas, and immersive post-story content.",
    },
    {
        id: 8,
        title: "Can you describe the faction system in Etharis?",
        content:
            "Etharis features three main factions: Cyberpunks, Steampunks, and Sandpunks. Each faction has its own culture, strengths, and gameplay style, from advanced technology to magical prowess. Players choose a faction to align with, impacting their journey and the game's story.",
    },
    {
        id: 9,
        title: "What are the main story themes in Etharis?",
        content:
            "Etharis' story revolves around factional conflicts, the quest for a mysterious artifact 'The Heart of Etharis', and the struggle for power and peace on the continent. The narrative is rich with political intrigue, moral choices, and strategic battles.",
    },
    {
        id: 10,
        title: "How does player choice affect the game in Etharis?",
        content:
            "In Etharis, player choices significantly impact the storyline and faction relationships. Decisions made during the game can alter alliances, open or close narrative paths, and ultimately shape the game's ending and the fate of the continent.",
    },
    {
        id: 11,
        title: "How does the roguelike RPG element work in Broken Toys?",
        content:
            "In Broken Toys, players navigate a generation ship as latent mutants, with the story unfolding over session-based plays. The game combines action RPG elements with roguelike features, such as procedurally generated levels and character evolution impacting future generations. It's a survival and exploration game set in a rich, narrative-driven universe.",
    },
    {
        id: 12,
        title: "Can you detail the character evolution in Broken Toys?",
        content:
            "In Broken Toys, character evolution is pivotal. Players start as mutants aboard a generation ship and evolve through gameplay. This evolution includes gaining unique abilities and traits, which can be passed on to future generations. The game's structure allows for a developing narrative influenced by player choices and character growth.",
    },
    {
        id: 13,
        title: "What is the significance of 'Broken Toys' in the game of the same name?",
        content:
            "In Broken Toys, 'Broken Toys' refer to AI mentor adaptive toys and artifacts scattered around the ship, serving as tools for character training and evolution. These items play a key role in character development and unlocking new game areas and abilities.",
    },
    {
        id: 14,
        title: "Describe the visual style of Broken Toys.",
        content:
            "Broken Toys features a 16-bit pixel-art style, delivering a nostalgic yet modern visual experience. The game's isometric top-down view complements its roguelike RPG elements, creating a unique and immersive visual narrative.",
    },
    {
        id: 15,
        title: "What makes the setting of Broken Toys unique?",
        content:
            "Broken Toys is set on a generation ship, Starship Terra, with a multi-tiered environment mimicking Earth. The setting is unique due to its blend of science fiction elements, tribal societies, and the mystery of a mission gone awry, offering a rich backdrop for exploration and adventure.",
    },
    {
        id: 16,
        title: "What challenges do players face in Broken Toys?",
        content:
            "Players in Broken Toys face survival challenges aboard a generation ship, including combat with evolved species, navigation of the ship's complex structure, and unraveling the mysteries behind the ship's mission and their own existence.",
    },
    {
        id: 17,
        title: "What are the key environments in Quantum Whiskers | Odyssey how were they generated?",
        content:
            "Quantum Whiskers | Odyssey features diverse environments like 'Virtual Reality Space', 'Ancient Temples', 'Cyberpunk Alleys', and 'Magical Forests'. These settings are crucial to setting the scene, offering unique perspective and exploration opportunities in each area. There were a total of 25 options and AI picked from the options for each breed.",
    },
    {
        id: 18,
        title: "What kind of player roles exist in Quantum Whiskers | Odyssey?",
        content:
            "Players in Quantum Whiskers | Odyssey take on roles like 'Teachers', 'Scientists', 'Developers', and 'Pilots', each with unique responsibilities and powers. These roles define player interactions and their impact on the game's universe.",
    },
    {
        id: 19,
        title: "How is player progression handled in Quantum Whiskers | Odyssey?",
        content:
            "Progression in Quantum Whiskers | Odyssey is based on exploring the multiverse, completing quests, and harnessing the unique powers of the Quantum Whiskers. Players' actions and choices lead to different outcomes and character developments.",
    },
    {
        id: 20,
        title: "Can you explain the gameplay of Quantum Whiskers | Odyssey?",
        content:
            "Quantum Whiskers | Odyssey is an epic game set in a multiverse. Players control sentient felines with cosmic powers, exploring various environments and utilizing their unique abilities. The game focuses on adventure, strategy, and exploring the intricate lore of a universe where actions can impact different dimensions.",
    },
];

export default dataFaq;
