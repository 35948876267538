const dataFaqShort = [
    {
        id: 1,
        title: "What is ERC721ODM and how does it impact gaming?",
        content:
            "ERC721ODM is a smart contract-based protocol designed for on-chain NFT metadata management. It revolutionizes gaming by allowing dynamic updates to NFTs, reflecting in-game events and achievements without traditional gas costs. This enhances the gaming experience by making in-game assets more interactive and responsive.",
    },
    {
        id: 2,
        title: "What are the benefits of using ERC721ODM for NFT projects?",
        content:
            "ERC721ODM offers several advantages for NFT projects, including centralized metadata management, access control, and the ability to manage token contracts efficiently. It simplifies handling multiple contracts and inconsistent metadata, making it an essential tool for dynamic NFTs in gaming and other applications.",
    },
    {
        id: 3,
        title: "What are the benefits of dynamic metadata in gaming?",
        content:
            "Dynamic metadata allows in-game assets to evolve in real-time, reflecting players' achievements and actions. This brings a new level of interaction and personalization to gaming, making the experience more engaging and tailored to individual gameplay styles.",
    },
    {
        id: 4,
        title: "How does gasless player onboarding work in ERC721ODM?",
        content:
            "ERC721ODM utilizes Ethereum Improvement Proposals like EIP-4337 & EIP-6551 to enable gasless transactions. This allows new players to join games without incurring upfront blockchain costs, smoothing their entry into the gaming world and enhancing overall accessibility.",
    },
    {
        id: 5,
        title: "Can ERC721ODM be integrated into existing game infrastructures?",
        content:
            "Yes, ERC721ODM is designed for flexibility and can be seamlessly integrated into existing game infrastructures. This allows developers to easily adopt dynamic NFT features in their games without extensive overhauls.",
    },
    {
        id: 6,
        title: "How does ERC721ODM handle metadata updates?",
        content:
            "ERC721ODM allows real-time metadata updates for NFTs on the blockchain. This means in-game assets can dynamically change based on events or achievements, ensuring a more interactive and engaging experience for players.",
    },
    {
        id: 7,
        title: "What sets Etharis apart from other strategy games?",
        content:
            "Etharis is a unique strategy game set on a continent with three distinct factions: Cyberpunks, Steampunks, and Sandpunks. It features a rich storyline, deep world-building, and the opportunity for players to align with a faction and influence the game's outcome. Etharis stands out with its focus on factional politics, moral dilemmas, and immersive post-story content.",
    },
    {
        id: 8,
        title: "Can you describe the faction system in Etharis?",
        content:
            "Etharis features three main factions: Cyberpunks, Steampunks, and Sandpunks. Each faction has its own culture, strengths, and gameplay style, from advanced technology to magical prowess. Players choose a faction to align with, impacting their journey and the game's story.",
    },
    {
        id: 9,
        title: "What are the main story themes in Etharis?",
        content:
            "Etharis' story revolves around factional conflicts, the quest for a mysterious artifact 'The Heart of Etharis', and the struggle for power and peace on the continent. The narrative is rich with political intrigue, moral choices, and strategic battles.",
    },
    {
        id: 10,
        title: "How does player choice affect the game in Etharis?",
        content:
            "In Etharis, player choices significantly impact the storyline and faction relationships. Decisions made during the game can alter alliances, open or close narrative paths, and ultimately shape the game's ending and the fate of the continent.",
    },
];

export default dataFaqShort;
