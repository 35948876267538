import img1 from "../images/common/logo_tech_1.png";
import img2 from "../images/common/logo_tech_2.png";
import img3 from "../images/common/logo_tech_3.png";
import img4 from "../images/common/logo_tech_4.png";

const dataPartner = [
    {
        id: 1,
        img: img1,
    },
    {
        id: 2,
        img: img2,
    },
    {
        id: 3,
        img: img3,
    },
    {
        id: 4,
        img: img4,
    },
];

export default dataPartner;
