const menus = [
    {
        id: 1,
        name: "W3N",
        links: "/",
        // namesub: [
        //     {
        //         id: 1,
        //         sub: "Team Details",
        //         links: "/team_details",
        //     },
        //     {
        //         id: 2,
        //         sub: "Launch with W3N",
        //         links: "/submit_w3n",
        //     },
        //     {
        //         id: 3,
        //         sub: "FAQs",
        //         links: "/faqs",
        //     },
        // ],
    },
    {
        id: 2,
        name: "Games",
        links: "/projects",
        namesub: [
            {
                id: 1,
                sub: "Broken Toys",
                links: "/game/broken-toys",
            },
            {
                id: 2,
                sub: "Etharis",
                links: "/game/etharis",
            },
        ],
    },
    // {
    //     id: 3,
    //     name: "Art",
    //     links: "/projects",
    //     namesub: [
    //         {
    //             id: 1,
    //             sub: "Quantum Whiskers",
    //             links: "/nft/quantum-whislers-odyssey",
    //         },
    //     ],
    // },
    {
        id: 4,
        name: "Tech",
        links: "/projects",
        namesub: [
            {
                id: 1,
                sub: "ERC721ODM",
                links: "/project/erc7210dm",
            },
            {
                id: 2,
                sub: "W3N GAME",
                links: "/",
            },
        ],
    },
    {
        id: 5,
        name: "Releases",
        links: "/releases",
    },
    // {
    //     id: 6,
    //     name: "Blog",
    //     links: "/blog_grid",
    // },
    {
        id: 7,
        name: "Contact",
        links: "/contact",
    },
];

export default menus;
