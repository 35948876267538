import img1 from "../images/common/icon_1.png";
import img2 from "../images/common/icon_2.png";
import img3 from "../images/common/icon_3.png";
import img4 from "../images/common/icon_4.png";
import img5 from "../images/common/icon_5.png";
import img6 from "../images/common/icon_6.png";
import img7 from "../images/common/icon_7.png";
import img8 from "../images/common/icon_8.png";
import img9 from "../images/common/icon_9.png";

const dataFeatured = [
    {
        id: 1,
        img: img1,
        title: "Dynamic NFT Management",
        text: "ERC721ODM brings to life dynamic, on-chain NFT metadata management, revolutionizing in-game assets and experiences.",
    },
    {
        id: 2,
        img: img2,
        title: "Immersive Game Worlds",
        text: "Explore the rich, multiversal saga of Quantum Whiskers | Odyssey and the complex continent of Etharis.",
    },
    {
        id: 3,
        img: img3,
        title: "Gasless Transactions",
        text: "With ERC721ODM, experience seamless onboarding with gasless transactions, thanks to EIP-4337 & EIP-6551 integration.",
    },
    {
        id: 4,
        img: img4,
        title: "Unique Game Mechanics",
        text: "Broken Toys introduces a unique blend of roguelike RPG elements and generational character evolution aboard a generation ship.",
    },
    {
        id: 5,
        img: img5,
        title: "Engaging Storylines",
        text: "Dive into compelling narratives, from the cosmic powers in Quantum Whiskers to the faction wars in Etharis.",
    },
    {
        id: 6,
        img: img6,
        title: "Evolutionary Characters",
        text: "Experience character growth in Broken Toys, where each choice shapes the future of travelers on a starship.",
    },
    {
        id: 7,
        img: img7,
        title: "Seamless Integration",
        text: "ERC721ODM’s flexible design allows for easy integration into existing game infrastructures, enhancing developer creativity.",
    },
    {
        id: 8,
        img: img8,
        title: "Diverse Environments",
        text: "From the cyberpunk alleys of Neonopolis to the magical forests of Quasaria, our games offer a range of dynamic worlds.",
    },
    {
        id: 9,
        img: img9,
        title: "Community & Collaboration",
        text: "Join a growing community of gamers and developers in reshaping the future of interactive digital experiences.",
    },
];

export default dataFeatured;
