import React from 'react';
import '../scss/component/_section.scss'; 
import '../scss/component/_box.scss'; 
import '../scss/component/_tf-section.scss'; 
import '../scss/component/_reponsive.scss'; 
import Banner from '../components/banner/banner_v3'; 
import dataBanner from '../assets/fake-data/data-banner';
import Team from '../components/team';
import dataTeam from '../assets/fake-data/data-team';
import CTA from '../components/cta';
import Faqs from '../components/faqs';
import dataFaqShort from '../assets/fake-data/data-faqs-short';
import Featured from '../components/featured';
import Featured2 from '../components/featured/Featured2';
import dataFeatured from '../assets/fake-data/data-feature';
import Technology from '../components/technology';
import dataPartner from '../assets/fake-data/data-partner';
import Releases from '../components/releases';

import dataReleases from '../assets/fake-data/data-releases';

function HomeOne(props) {
 
    return (
        <div className='header-fixed main home1 counter-scroll'>

           {<Banner data={dataBanner} />}

            {<Featured data={dataFeatured} />}

            {/* {<Releases data={dataReleases} />} */}

            {<Featured2 data={dataFeatured} />}

            {<Team data={dataTeam} />}

            {<Technology data={dataPartner} />}

            {<Faqs data={dataFaqShort} />}  

            {<CTA />}

        </div>
    );
}

export default HomeOne;