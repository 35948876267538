import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import img from '../../assets/images/backgroup/bg_team_section.png'
import shape from '../../assets/images/common/shape_3.png'
import shape1 from '../../assets/images/common/shape_4.svg'
import { useState } from 'react';



Team.propTypes = {
    data: PropTypes.array,
};

function Team(props) {
    const { data } = props;

    const [dataTitle] = useState(
        {
            title: 'Meet with the team',
        }
    )
    return (
        <section className="tf-section tf_team">
            <div className="overlay">
                <img src={img} alt="" />
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tf-title mb40" data-aos="fade-up" data-aos-duration="800">
                            <h2 className="title">
                                {dataTitle.title}
                            </h2>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="team-box-wrapper">

                            {
                                data.map(item => (
                                    <div key={item.id} className="team-box" data-aos="fade-up" data-aos-delay="100" data-aos-duration="800">
                                        <img className="shape" src={shape} alt="" />
                                        <img className="shape_ecfect" src={shape1} alt="" />
                                        <div className="image">
                                            <img src={item.img} alt="" />
                                        </div>
                                        <div className="content">
                                            {/* <h5 className="name"><Link to="/team_details">{item.name}</Link></h5> */}
                                            <h5 className="name">{item.name}</h5>
                                            <p className="position">{item.unit}</p>
                                            <ul className="social">
                                                {
                                                    item.social.twitter ? <li>
                                                        <a href={item.social.twitter} target='_blank'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="22" height="22" viewBox="0 0 24 24">
                                                                <path fill='#798DA3' d="M 2.8671875 3 L 9.7363281 12.818359 L 2.734375 21 L 5.3808594 21 L 10.919922 14.509766 L 15.460938 21 L 21.371094 21 L 14.173828 10.697266 L 20.744141 3 L 18.138672 3 L 12.996094 9.0097656 L 8.7988281 3 L 2.8671875 3 z"></path>
                                                            </svg>
                                                        </a>
                                                    </li> : ""
                                                }
                                                {
                                                    item.social.linkedin ? <li>
                                                        <a href={item.social.linkedin} target='_blank'>
                                                            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M18.3003 17.8V11.354C18.3003 8.18599 17.6183 5.76599 13.9223 5.76599C12.1403 5.76599 10.9523 6.73399 10.4683 7.65799H10.4243V6.05199H6.92627V17.8H10.5783V11.97C10.5783 10.43 10.8643 8.95599 12.7563 8.95599C14.6263 8.95599 14.6483 10.694 14.6483 12.058V17.778H18.3003V17.8Z" fill="#798DA3" />
                                                                <path d="M0.986328 6.052H4.63833V17.8H0.986328V6.052Z" fill="#798DA3" />
                                                                <path d="M2.8122 0.200012C1.6462 0.200012 0.700195 1.14601 0.700195 2.31201C0.700195 3.47801 1.6462 4.44601 2.8122 4.44601C3.9782 4.44601 4.9242 3.47801 4.9242 2.31201C4.9242 1.14601 3.9782 0.200012 2.8122 0.200012Z" fill="#798DA3" />
                                                            </svg>
                                                        </a>
                                                    </li> : ""
                                                }
                                                {
                                                    item.social.web ? <li>
                                                        <a href={item.social.web} target='_blank'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 512 512"><path fill='#798DA3' d="M352 256c0 22.2-1.2 43.6-3.3 64H163.3c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64H348.7c2.2 20.4 3.3 41.8 3.3 64zm28.8-64H503.9c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64H380.8c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32H376.7c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0H167.7c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0H18.6C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192H131.2c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64H8.1C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6H344.3c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352H135.3zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6H493.4z" /></svg>
                                                        </a>
                                                    </li> : ""
                                                }


                                                {
                                                    item.social.web === "" && item.social.linkedin === "" && item.social.twitter === "" ? <li style={{ opacity: "0", pointerEvents: "none" }}>
                                                        <Link to="#">
                                                            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M18.3003 17.8V11.354C18.3003 8.18599 17.6183 5.76599 13.9223 5.76599C12.1403 5.76599 10.9523 6.73399 10.4683 7.65799H10.4243V6.05199H6.92627V17.8H10.5783V11.97C10.5783 10.43 10.8643 8.95599 12.7563 8.95599C14.6263 8.95599 14.6483 10.694 14.6483 12.058V17.778H18.3003V17.8Z" fill="#798DA3" />
                                                                <path d="M0.986328 6.052H4.63833V17.8H0.986328V6.052Z" fill="#798DA3" />
                                                                <path d="M2.8122 0.200012C1.6462 0.200012 0.700195 1.14601 0.700195 2.31201C0.700195 3.47801 1.6462 4.44601 2.8122 4.44601C3.9782 4.44601 4.9242 3.47801 4.9242 2.31201C4.9242 1.14601 3.9782 0.200012 2.8122 0.200012Z" fill="#798DA3" />
                                                            </svg>
                                                        </Link>
                                                    </li> : ""
                                                }

                                            </ul>
                                        </div>
                                    </div>
                                ))
                            }


                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}

export default Team;