import React from 'react';

import PageTitle from '../components/pagetitle';
import CTA from '../components/cta/cta_v2';

import icon from '../assets/images/common/down.svg'
import iconWhite from '../assets/images/common/down_white.svg'




function Roadmap(props) {
    return (
        <div className='inner-page'>
            {<PageTitle title='Release Timeline' />}

            <section className="tf-section roadmap">
            <div className="container">
                <div className="row"> 
                    <div className="col-md-12"> 
                        <div className="roadmap-wrapper-style2" data-aos="fade-up" data-aos-duration="1200">
                            <div className="left">
                                {/* Etharis Finalizing Map Design */}
                                <div className="roadmap-box-style active">
                                    <div className="icon">
                                        <img src={iconWhite} alt="" />
                                    </div>
                                    <div className="content">
                                        <h6 className="date">December 2023</h6>
                                        <ul>
                                            <li>Etharis: Finalizing Map Design</li>
                                            <li>Completion of mob sprites and balancing</li>
                                            <li>Godot engine conversion completion</li>
                                        </ul>
                                    </div>
                                </div>

                                    
                                {/* Etharis Alpha Testing */}
                                <div className="roadmap-box-style active">
                                    <div className="icon">
                                        <img src={iconWhite} alt="" />
                                    </div>
                                    <div className="content">
                                        <h6 className="date">Q1 2024</h6>
                                        <ul>
                                            <li>Etharis: Start of Alpha Testing</li>
                                            <li>Broken Toys: Development Progress</li>
                                            <li>ERC721ODM Beta Testing for 200k Players</li>
                                        </ul>
                                    </div>
                                </div>

                                {/* Etharis and Broken Toys Website Launch */}
                                <div className="roadmap-box-style">
                                    <div className="icon">
                                        <img src={icon} alt="" />
                                    </div>
                                    <div className="content">
                                        <h6 className="date">Q2 2024</h6>
                                        <ul>
                                            <li>Etharis: Beta Testing Commencement</li>
                                            <li>Broken Toys: Alpha Testing Starts</li>
                                            <li>Website Launch for Etharis and Broken Toys</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="right">
                                {/* Quantum Whiskers Reveal */}
                                <div className="roadmap-box-style active">
                                    <div className="icon">
                                        <img src={iconWhite} alt="" />
                                    </div>
                                    <div className="content">
                                        <h6 className="date">December 2023</h6>
                                        <ul>
                                            <li>Quantum Whiskers: First 10,000 Samples Reveal</li>
                                            <li>AI Model Training and Generation</li>
                                            <li>Preview of Quantum Whiskers NFTs</li>
                                        </ul>
                                    </div>
                                    </div>
                                {/* Broken Toys Beta Testing */}
                                <div className="roadmap-box-style">
                                    <div className="icon">
                                        <img src={icon} alt="" />
                                    </div>
                                    <div className="content">
                                        <h6 className="date">Q3 2024</h6>
                                        <ul>
                                            <li>Broken Toys: Beta Testing Begins</li>
                                            <li>Continuous Development and Testing of ERC721ODM</li>
                                            <li>Further Enhancements to Etharis Post-Beta</li>
                                        </ul>
                                    </div>
                                </div>

                                {/* Ongoing Development of W3N Games */}
                                <div className="roadmap-box-style">
                                    <div className="icon">
                                        <img src={icon} alt="" />
                                    </div>
                                    <div className="content">
                                        <h6 className="date">Throughout 2024</h6>
                                        <ul>
                                            <li>Ongoing Development of W3N Games</li>
                                            <li>Scaling and Bandwidth Testing for ERC721ODM</li>
                                            <li>Enhancing User Experience and Performance</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>

    </section>
            {<CTA />}
            
        </div>
    );
}

export default Roadmap;